import React, { useState } from 'react'
import FormBoxLayout from 'src/components/FormBoxLayout'
import Form from 'src/components/Form'
import FormGroup from 'src/components/FormGroup'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import ActionButtonFormGroup from 'src/components/ActionButtonFormGroup'
import Link from 'src/components/Link'

function Login () {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const handleLogin = () => {
    alert('coming soon')
  }

  const title = 'Login'

  return (
    <FormBoxLayout title={title}>
      <Form label={title} onSubmit={handleLogin}>
        <TextFieldFormGroup
          inputProps={{
            autoFocus: true,
            label: 'Email',
            value: email,
            placeholder: 'satoshi@example.com',
            onChange: setEmail,
            type: 'text'
          }}
        />
        <TextFieldFormGroup
          inputProps={{
            label: 'Password',
            value: password,
            placeholder: 'Password',
            onChange: setPassword,
            type: 'password'
          }}
        />
        <ActionButtonFormGroup buttonText={'Login'} />
      </Form>
      <FormGroup direction="column">
        <div>
          <Link href="/signup">Create account</Link>
        </div>
        <div>
          <Link href="/forgot-password">Forgot password</Link>
        </div>
      </FormGroup>
    </FormBoxLayout>
  )
}

export default Login
