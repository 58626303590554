import React from 'react'
import ButtonFormGroup from 'src/components/ButtonFormGroup'
import styles from './ActionButtonFormGroup.module.css'

interface Props {
  buttonText: string
}

function ActionButtonFormGroup (props: Props) {
  const { buttonText } = props

  return (
    <ButtonFormGroup
      className={styles.formGroup}
      buttonProps={{
        buttonText,
        type: 'submit'
      }}
    />
  )
}

export default ActionButtonFormGroup
