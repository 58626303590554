import React from 'react'
import styles from './Link.module.css'

function Link (props: any) {
  return (
    <a className={styles.link} {...props}>
      {props.children}
    </a>
  )
}

export default Link
