import React from 'react'
import styles from './Button.module.css'

interface Props {
  buttonText: string
  type?: string
}

function Button (props: Props) {
  const { buttonText } = props

  return (
    <button className={styles.button} {...props}>
      {buttonText}
    </button>
  )
}

export default Button
