import React from 'react'
import styles from './Form.module.css'

interface Props {
  onSubmit?: () => void
  label?: string
  children: any
}

function Form (props: Props) {
  const { onSubmit, label, children } = props

  const handleSubmit = (event: any) => {
    event.preventDefault()

    if (onSubmit) {
      onSubmit()
    }
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      {label ? <h3 className={styles.label}>{label}</h3> : null}
      {children}
    </form>
  )
}

export default Form
