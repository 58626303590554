import React from 'react'
import styles from './TextLogo.module.css'

function TextLogo () {
  return (
    <a className={styles.logo} href="/">
      <h1 className={styles.title} title="cointop">
        <span className={styles.green}>❯</span>
        <span className={styles.cyan}>❯</span>
        <span className={styles.green}>❯</span>
        <span className={styles.cyan}>cointop</span>
        <span className={styles.ext}>.sh</span>
      </h1>
    </a>
  )
}

export default TextLogo
