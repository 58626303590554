import React from 'react'
import FormGroup from 'src/components/FormGroup'
import TextField from 'src/components/TextField'

interface Props {}

function TextFieldFormGroup (props: any) {
  const { inputProps } = props

  return (
    <FormGroup {...props}>
      <TextField {...inputProps} />
    </FormGroup>
  )
}

export default TextFieldFormGroup
