import React from 'react'
import classnames from 'classnames'
import styles from './FormGroup.module.css'

interface Props {
  direction?: string
  align?: string
}

function FormGroup (props: Props) {
  const { align, direction, className, children } = props

  const alignClass =
    align === 'left' ? styles.left : align === 'right' ? styles.right : null
  const directionClass = direction === 'column' ? styles.column : null

  return (
    <div
      className={classnames(
        styles.formGroup,
        alignClass,
        directionClass,
        className
      )}
    >
      {children}
    </div>
  )
}

export default FormGroup
