import React from 'react'
import classnames from 'classnames'
import styles from './ButtonFormGroup.module.css'
import FormGroup from 'src/components/FormGroup'
import Button from 'src/components/Button'

interface Props {}

function ButtonFormGroup (props: any) {
  const { className, buttonProps } = props

  return (
    <FormGroup className={classnames(className)} {...props}>
      <Button {...buttonProps} />
    </FormGroup>
  )
}

export default ButtonFormGroup
