import React from 'react'
import styles from './TextField.module.css'

interface Props {
  type: string
  value: string
  onChange: (value: string) => void
  label: string
  placeholder?: string
  autoFocus?: boolean
  error?: string
}

function TextField (props: Props) {
  const { type, value, onChange, label, placeholder, autoFocus, error } = props

  const handleChange = (event: any) => {
    onChange(event.target.value)
  }

  return (
    <div className={styles.container}>
      <label className={styles.label}>{label}</label>
      <input
        className={styles.input}
        type={type}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        autoFocus={autoFocus}
      />
      {error ? <div className={styles.error}>{error}</div> : null}
    </div>
  )
}

export default TextField
