import React from 'react'
import Meta from 'src/components/Meta'
import TextLogo from 'src/components/TextLogo'
import styles from './FormBoxLayout.module.css'

function FormBoxLayout (props: any) {
  const { children, title } = props
  return (
    <main className={styles.layoutContainer}>
      <div className={styles.outerContainer}>
        <Meta title={`cointop - ${title}`} />
        <div className={styles.logo}>
          <TextLogo />
        </div>
        <div className={styles.innerContainer}>{children}</div>
      </div>
    </main>
  )
}

export default FormBoxLayout
