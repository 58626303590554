import React from 'react'
import { Helmet } from 'react-helmet'
import styles from 'src/styles/root.module.css'

const Meta = (props: any) => {
  return (
    <Helmet
      htmlAttributes={{
        lang: 'en'
      }}
    >
      <meta charSet="UTF-8" />
      <meta http-equiv="content-language" content="en-us" />
      <meta
        name="description"
        content={
          props.description ||
          'A fast and interactive web application for tracking cryptocurrencies. Features include: vim-inspired key bindings, fast pagination, save and view favorite coins, chart views, and more.'
        }
      />
      <meta
        name="keywords"
        content="cointop, coin top, top coins, top coin prices, coin top prices, coin market cap prices, coin top table, cryptocurrency prices table, terminal, coin market cap, crypto currencies, digital currencies, tracking, monitoring, cli, command line interface, interactive, terminal, console, ui, mac, linux, windows, htop, vim, online, web, api"
      />
      <meta name="robots" content="index,follow" />
      <meta name="googlebot" content="index,follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="application-name" content="Cointop" />
      <meta name="apple-mobile-web-app-title" content="Cointop" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black" />
      <meta
        name="google-site-verification"
        content="WKxlYrT98LMG7TNlOTOs5DbIi08lfbPOt4vGc4xpnHc"
      />
      <link rel="apple-touch-icon" href="/icon.png" />
      <title>{props.title || 'cointop'}</title>
      <body className={styles.body} />
      <script
        data-ad-client="ca-pub-0862073952802854"
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      ></script>
    </Helmet>
  )
}

export default Meta
